<div *ngIf="navType === 'sideNav'" id="side-nav" class="side-menu">
  <ng-container *ngIf="isCollapsed; else miniMenu">
    <esqep-navbar-menu
      [menuLoading]="menuLoading"
      [claimNotificationMap]="claimNotificationMap"
      [menuData]="menuData"
    ></esqep-navbar-menu>
  </ng-container>
  <ng-template #miniMenu>
    <div class="mini-menu">
      <ug-mini-menu
        [menuLoading]="menuLoading"
        [claimNotificationMap]="claimNotificationMap"
        [menuData]="menuData"
      ></ug-mini-menu>
    </div>
  </ng-template>
</div>

<div *ngIf="navType === 'offCanvas'">
  <div class="offcanvas-body p-0 overflow-hidden">
    <esqep-navbar-menu
      [menuLoading]="menuLoading"
      [claimNotificationMap]="claimNotificationMap"
      [menuData]="menuData"
    ></esqep-navbar-menu>
  </div>
</div>
