import { Routes } from '@angular/router';
import { OktaCallbackComponent } from '@okta/okta-angular';

export const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login/callback',
    component: OktaCallbackComponent,
  },
  {
    path: '',
    loadChildren: () => import('./core/core.routes').then((x) => x.CORE_ROUTES),
  },
  {
    path: '',
    loadChildren: () =>
      import('./submission/submission.routes').then((x) => x.SUBMISSION_ROUTES),
  },
  {
    path: '',
    loadChildren: () =>
      import('./claims/claims.routes').then((x) => x.CLAIMS_ROUTES),
  },
  {
    path: '',
    loadChildren: () =>
      import('./claim/claim.routes').then((x) => x.CLAIM_ROUTES),
  },
  {
    path: '',
    loadChildren: () =>
      import('./events/events.routes').then((x) => x.EVENTS_ROUTES),
  },
  {
    path: '',
    loadChildren: () =>
      import('./dashboard/dashboard.routes').then((x) => x.DASHBOARD_ROUTES),
  },
  {
    path: '',
    loadChildren: () =>
      import('./job-role/job-role.routes').then((x) => x.JOB_ROLE_ROUTES),
  },

  {
    path: '',
    loadChildren: () =>
      import('./person/person.routes').then((x) => x.PERSON_ROUTES),
  },
  {
    path: '',
    loadChildren: () =>
      import('./capability-framework/capability-framework.routes').then(
        (x) => x.COMPETENCY_ROUTES,
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./dynamic-groups/dynamic-groups.routes').then(
        (x) => x.DYNAMIC_GROUPS_ROUTES,
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./projects/projects.routes').then((x) => x.PROJECTS_ROUTES),
  },
  { path: '**', redirectTo: '/page-not-found', pathMatch: 'full' },
];
