import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit, inject } from '@angular/core';

import { Alert } from './alert';

import { DatePipe, NgClass, NgFor, TitleCasePipe } from '@angular/common';
import { AlertLevels, AlertService } from '../services/alert.service';

@Component({
  selector: 'ug-alert',
  standalone: true,
  imports: [NgFor, NgClass, DatePipe, TitleCasePipe],
  templateUrl: './alert.component.html',
  styleUrls: [],
  animations: [
    trigger('fadeShrinkInOut', [
      state('inOut', style({ height: '*', opacity: '*' })),
      transition('* => void', [
        style({ height: '*', opacity: '*' }),
        animate(250, style({ height: 0, opacity: 0 })),
      ]),
      transition('void => *', [
        style({ height: 0, opacity: 0 }),
        animate(150, style({ height: '*', opacity: 1 })),
      ]),
    ]),
  ],
})
export class AlertComponent implements OnInit {
  private alertService = inject(AlertService);

  ngOnInit() {}

  onAlertDismiss(alertId: number) {
    this.alertService.dimsissAlert(alertId);
  }

  get alerts(): Array<Alert> {
    return this.alertService.alerts;
  }

  getAlertCountdown(alertIndex): string {
    let alertCountdownMessage = '';
    if (this.alerts[alertIndex].secondsUntilDismissed) {
      alertCountdownMessage = ` (${this.alerts[alertIndex].secondsUntilDismissed}s)`;
    }
    return alertCountdownMessage;
  }

  getAlertBadgeClass(alertIndex: number): string {
    let alertBadgeClass = '';
    switch (this.alertService.alerts[alertIndex].type) {
      case AlertLevels.ERROR:
        alertBadgeClass = 'bg-danger';
        break;
      case AlertLevels.WARNING:
        alertBadgeClass = 'bg-warning';
        break;
      case AlertLevels.INFO:
        alertBadgeClass = 'bg-info';
        break;
      case AlertLevels.SUCCESS:
        alertBadgeClass = 'bg-success';
        break;
      default:
        alertBadgeClass = 'bg-secondary';
    }
    return alertBadgeClass;
  }
}
