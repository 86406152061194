<ng-template #inactivityWarningModal let-modal>
  <div class="modal-content">
    <div
      class="modal-header"
      [ngClass]="getTimeoutModalHeaderStyle(inactiveTime)"
    >
      <h5 class="modal-title" id="timeoutWarningModalTitle">
        Inactivity Warning
      </h5>
    </div>
    <div class="modal-body">
      <p>{{ inactivityMessage1 }}</p>
      <p *ngIf="inactivityMessage2" class="text-danger fw-bold">
        {{ inactivityMessage2 }}
      </p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn esqepBtn"
        (click)="onInactivityDismiss(); modal.close()"
      >
        I'm still here!
      </button>
      <button type="button" class="btn btn-outline-danger" (click)="logout()">
        Log out
      </button>
    </div>
  </div>
</ng-template>
<ug-alert></ug-alert>
<ng-container *ngIf="isSignedIn">
  <header class="container-application" [ngClass]="navbarBgClass">
    <nav
      id="top-nav"
      class="fixed-top d-flex justify-content-between align-items-center"
      [ngClass]="navbarBgClass"
    >
      <div class="nav-button-container">
        <button
          type="button"
          class="toggleSideBarBtn d-none d-lg-block"
          (click)="toggleMenu()"
        >
          <i class="fa fa-bars fa-2x"></i>
        </button>
        <button
          class="toggleSideBarBtn d-lg-none"
          type="button"
          (click)="toggleOffCanvasMenu()"
        >
          <i class="fa fa-bars fa-2x"></i>
        </button>
        <a routerLink="/home">
          <img
            [src]="'../assets/' + clientLogoAsset"
            alt="Client logo"
            id="clientLogo"
          />
        </a>
      </div>
      <div class="d-flex align-items-center">
        <div class="text-center">
          <a
            *ngIf="helpUrl"
            title="Click here to get help on how to use this page"
            [href]="helpUrl"
            target="_blank"
            class="me-3 btn btn-outline-light"
            rel="noreferrer noopener"
          >
            Help
          </a>
        </div>
        <ug-person-detail-navbar
          *ngIf="authService.userUPN"
          [personUPN]="authService.userUPN"
        ></ug-person-detail-navbar>
      </div>
    </nav>
  </header>
</ng-container>

<main class="row min-vh-100 m-0 flex-nowrap">
  <ng-container *ngIf="isSignedIn">
    <nav class="col-auto px-0 d-none d-lg-block">
      <ug-navbar
        [inUserGroup]="inUserGroup"
        [isCollapsed]="navCollapsed"
        [navType]="'sideNav'"
      >
      </ug-navbar>
    </nav>
  </ng-container>
  <div class="col-10 flex-fill px-5 main-content">
    <div *ngIf="isSignedIn" class="justify-content-between align-items-center">
      <div class="page-title">
        <h1 *ngIf="router.url.split('?')[0] !== '/home'; else homePageTitle">
          {{ title }}
        </h1>
        <ng-template #homePageTitle>
          <h1>Welcome back, {{ authService.me?.givenName }}!</h1>
        </ng-template>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <router-outlet
          (activate)="onRouterOutletActivate($event)"
        ></router-outlet>
      </div>
    </div>
  </div>
</main>

<footer
  *ngIf="isSignedIn"
  [ngClass]="navbarBgClass"
  class="footer col-12 pt-4 pb-4 container-fluid"
  id="footer-main"
>
  <div class="row text-center text-sm-left align-items-sm-center">
    <div class="col-sm-2">
      <a href="https://sqeptech.com/esqep/" target="_blank">
        <img
          [ngSrc]="'../assets/sqeptech-logo-white-32x100.png'"
          alt="SQEPtech logo"
          width="108"
          height="32"
          priority
        />
      </a>
    </div>
    <div class="col-sm-8">
      <p class="text-sm mb-0">
        &copy; {{ copyrightDate | date: 'yyyy' }}
        <a href="https://sqeptech.com/" class="h6 text-sm" target="_blank"
          >SQEPtech Ltd</a
        >
        All rights reserved.
      </p>
      <span class="navbar-text">Version: {{ appVersion }}</span>
    </div>
    <div class="col-sm-2 mb-md-0">
      <ul class="nav justify-content-center justify-content-md-end">
        <li class="nav-item">
          <a
            class="pr-0"
            href="https://sqeptech.com/privacy-policy/"
            target="_blank"
            >Privacy Policy</a
          >
        </li>
      </ul>
    </div>
  </div>
  <div class="row" *ngIf="authService.diagShow">
    <div class="col small">
      <p>{{ this.spinnerInProgressUrls }}</p>
      <p>Is Signed In = {{ isSignedIn }}</p>
      <p>In User Group = {{ inUserGroup }}</p>
      <p>Is Admin = {{ hasAdminRole }}</p>
    </div>
  </div>
</footer>
